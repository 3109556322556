<template>
  <div class="airport__card flex min-h-[234px] w-64 shrink-0 flex-col rounded-md bg-white shadow-card hover:text-blue-600 xs:w-auto xs:min-w-full xs:shrink">
    <a
      :class="{ 'flex flex-col h-full': be606ukQuickWinsRunning }"
      :href="formatUrl(airport.slug)"
      class="pb-5 hover:no-underline xs:pb-0"
    >
      <div class="flex place-content-center">
        <nuxt-img
          class="shadow-image h-32 w-full rounded-t-md object-cover"
          :src="airport.airportImage"
          :alt="airport.name"
          loading="lazy"
        />
      </div>
      <div
        class="airport__card__title text-[14px] xs:text-base mt-3 px-4 font-bold leading-relative text-black"
        :class="{ 'pb-4': !airport.from_price }"
      >
        {{ airport.name }}
      </div>

      <experiment-toggle name="homUkQuickWins">
        <template #a>
          <div
            v-if="airport.from_price"
            class="p-4 pt-0 font-sans text-gray-400 text-[14px] xs:text-base"
          >
            {{
              $i18n("templates.from-x-euro-week", {
                amount: formatPrice(airport.from_price),
              })
            }}
          </div>
        </template>
        <template #b>
          <div class="px-4 pt-4 md:p-4">
            <div
              v-if="airport.from_price"
              class="text-[14px] xs:text-base mt-auto py-3 rounded-[4px] border w-full text-center font-bold border-orange-500 hover:bg-orange-500 text-orange-500 hover:text-white"
            >
              {{
                $i18n("templates.from-x-euro-week", {
                  amount: formatPrice(airport.from_price),
                })
              }}
            </div>
          </div>
        </template>
      </experiment-toggle>
    </a>
  </div>
</template>

<script>
import ExperimentToggle from "~/components/experiments/ExperimentToggle.vue";

export default {
  components: {
    ExperimentToggle,
  },
  props: {
    airport: {
      type: Object,
      required: false,
      default: () => undefined,
    },
  },
  data() {
    return { be606ukQuickWinsRunning: false };
  },
  computed: {
    language() {
      return this.$store.state.language;
    },
  },
  created() {
    this.be606ukQuickWinsRunning= this.$store?.getters?.experimentVariant(this.name) === "b";
  },
  methods: {
    formatUrl(slug) {
      return `/${slug}/`;
    },
    formatPrice(fromPrice) {
      if(!fromPrice||+fromPrice===0) {
        return undefined;
      }

      return new Intl.NumberFormat(this.language.lang,{
        style: "currency",
        currency: this.language.currency.iso_code??"EUR",
      }).format(fromPrice);
    },
  },
};
</script>
<style lang="scss">
.airport {
  &__card {
    $self: &;
    &:hover {
      #{$self}__title {
        @apply text-blue-600;
      }
    }
  }
}
</style>
