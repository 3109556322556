var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.review)?_c('div',{staticClass:"review-card bg-white rounded-md flex flex-col gap-y-3 my-5 p-2 xxs:p-5 shadow-card font-sans"},[_c('div',{staticClass:"gap-x-2 border-b border-athens pb-3"},[_c('div',{staticClass:"flex flex-row w-full justify-between h-auto mb-2.5 overflow-y-hidden"},[_c('a',{staticClass:"xs:text-base text-md font-bold text-black hover:underline",attrs:{"target":"_blank","href":`/${_vm.review.airport_content_slug}/${_vm.review.merchant_slug}.html`}},[_vm._v("\n        "+_vm._s(_vm.review.merchant)+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"text-base xs:text-base font-bold flex items-center"},[_c('Stars',{staticClass:"block w-6",attrs:{"rating":1,"hide-over-rating":true,"small":true}}),_vm._v(" "),_c('div',[_vm._v("\n          "+_vm._s(_vm.score)+"\n        ")])],1)]),_vm._v(" "),_c('div',{staticClass:"text-[12px] xs:text-md md:test-base text-manatee w-full"},[_vm._v("\n      "+_vm._s(_vm.$i18n('merchant.review-parked-between-dates', {
          start: _vm.review.arrival ? _vm.arrival : '',
          end: _vm.review.departure ? _vm.departure : '',
        }))+"\n    ")])]),_vm._v(" "),_c('div',{ref:"wrapper",staticClass:"review-card__content-wrapper border-b border-athens pb-3 box-content relative",class:{ 'max-h-[420px]': _vm.expanded, 'h-[110px]': !_vm.expanded }},[_c('div',{ref:"content",staticClass:"font-sans xs:text-base text-md",staticStyle:{"line-height":"1.88"}},[_c('div',{ref:"reviewContent",staticClass:"overflow-hidden",class:{
          'overflow-y-auto !max-h-[400px]': _vm.expanded,
          'h-[85px]': !_vm.expanded,
        }},[_vm._v("\n        "+_vm._s(_vm.review.content)+"\n      ")]),_vm._v(" "),(
          !_vm.expanded && _vm.review.content.split(' ').length > _vm.charactersLength
        )?_c('a',{staticClass:"text-manatee xs:text-base text-sm hover:underline hover:text-blue-600 cursor-pointer lowercase mb-2.5",on:{"click":_vm.handleReviewCardHeight,"keypress":_vm.handleReviewCardHeight}},[_vm._v("\n        "+_vm._s('...' + _vm.$i18n('templates.read-more'))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.expanded && _vm.review.content.split(' ').length > _vm.charactersLength)?_c('a',{staticClass:"text-manatee xs:text-base text-md hover:underline hover:text-blue-600 cursor-pointer lowercase mb-2.5",on:{"click":_vm.handleReviewCardHeight,"keypress":_vm.handleReviewCardHeight}},[_vm._v("\n        "+_vm._s(_vm.$i18n('templates.read-less'))+"\n      ")]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"flex place-content-between place-items-center"},[_c('div',{staticClass:"flex items-center items-stretch"},[_c('div',{staticClass:"font-bold xs:text-base text-md capitalize"},[_vm._v("\n        "+_vm._s(_vm.review.name)+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"ml-3 text-manatee xxs:text-md md:test-base text-sm hidden sm:block"},[_vm._v("\n        "+_vm._s(_vm.parkingType)+"\n      ")])]),_vm._v(" "),_c('div',{staticClass:"text-manatee xxs:text-md md:test-base text-[14px]"},[_vm._v("\n      "+_vm._s(_vm.date)+"\n    ")])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }