<template>
  <div class="xs:container px-5 sm:px-10 pt-10 md:pt-[30px]">
    <div class="flex justify-center">
      <div
        class="flex justify-between items-center bg-[url('https://assets.parkos.com/assets/img/spring-banner-mobile-2.png')] md:bg-[url('https://assets.parkos.com/assets/img/group-16.png')] bg-no-repeat bg-center bg-cover max-w-[987px] w-full text-white py-[25px] px-[20px] md:py-[40px] md:px-[30px] rounded-md"
      >
        <div
          class="flex flex-col md:flex-row items-start md:items-center w-full"
        >
          <div class="flex flex-row">
            <div class="block mr-[10px] xxxs:mr-[20px] md:mr-[30px]">
              <div class="relative">
                <svg
                  class="w-[82px] h-[82px]"
                  viewBox="0 0 142 142"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m71 134.34-11.107 6.786-8.466-9.886-12.66 3.021-4.997-12.018-12.975-1.038-1.038-12.975-12.018-4.997 3.021-12.66-9.886-8.466L7.66 71 .874 59.893l9.886-8.466-3.021-12.66 12.018-4.997 1.038-12.975 12.975-1.038 4.997-12.018 12.66 3.021L59.893.874 71 7.66 82.107.874l8.466 9.886 12.66-3.021 4.997 12.018 12.975 1.038 1.038 12.975 12.018 4.997-3.021 12.66 9.886 8.466L134.34 71l6.786 11.107-9.886 8.466 3.021 12.66-12.018 4.997-1.038 12.975-12.975 1.038-4.997 12.018-12.66-3.021-8.466 9.886z"
                    fill="#F06B1F"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <div
                  style="line-height: normal"
                  class="w-[66px] h-[45px] text-sm font-bold font-sans absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                >
                  <p
                    class="text-center text-[24px] font-cocogoose font-normal"
                  >
                    €10
                  </p>
                  <p class="text-center text-[12px]">Korting</p>
                </div>
              </div>
            </div>
            <div class="md:flex items-center">
              <div style="line-height: normal" class="w-full">
                <h2 class="font-cocogoose text-base md:text-[24px]">
                  Krijg €10 korting bij Bob Autowas
                </h2>
                <p class="mt-2.5 text-md md:text-[18px]">
                  Reserveer voor 28 april via Parkos
                </p>
                <p class="hidden md:block pa-lh-normal mt-2.5 text-sm">
                  *Zodra je hebt gereserveerd, ontvang je de korting per email
                </p>
              </div>
              <a
                role="button"
                tabindex="0"
                class="block md:hidden w-full mt-2.5 font-cocogoose text-[14px] overflow-hidden overflow-ellipsis hover:bg-blaze-orange-500 bg-orange-500 text-white rounded-md text-center cursor-pointer whitespace-nowrap max-w-min px-[25px] py-[12px]"
                @click="ctaTrigger"
              >
                <span> Reserveer nu </span>
              </a>
            </div>
          </div>

          <p class="block md:hidden pa-lh-normal mt-2.5 text-sm">
            *Zodra je hebt gereserveerd, ontvang je de korting per email
          </p>
          <a
            role="button"
            tabindex="0"
            class="hidden md:block w-full ml-auto mt-2.5 font-cocogoose text-[14px] overflow-hidden overflow-ellipsis hover:bg-blaze-orange-500 bg-orange-500 text-white rounded-md text-center cursor-pointer whitespace-nowrap max-w-min px-[25px] py-[12px]"
            @click="ctaTrigger"
          >
            <span> Reserveer nu </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    ctaTrigger() {
      document.getElementById("search-section").scrollIntoView({
        behavior: "auto",
        block: "center",
      });
      dataLayer.push({
        event: "experiment_data",
        dl_experiment_id: "38/24",
        dl_experiment_action: "cta_click",
        dl_experiment_value: "",
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
