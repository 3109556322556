<template>
  <div class="md:pt-20 pt-10">
    <div class="xs:container px-5 sm:px-10">
      <LazyHydrate when-visible>
        <div id="top-3-airports" class="font-sans">
          <h2 class="text-[16px] font-sans xs:text-xl md:text-[18px] font-bold">
            {{ $i18n("general.top-3-popular-airports") }}
          </h2>
          <div class="grid gap-x-[30px] grid-cols-1 md:grid-cols-3">
            <div
              class="relative rounded-md h-[225px] mt-5"
              v-for="airport in top3Airports"
              :key="airport.id"
            >
              <nuxt-img
                format="webp"
                class="shadow-image absolute rounded-md h-full top-0 left-0 w-full object-cover"
                :src="airport.airportImage"
                :alt="airport.name"
                loading="lazy"
                height="225" 
                width="100%"
              />
              <div
                class="w-full h-full flex flex-col justify-end items-start p-7.5 backdrop-brightness-75 text-white font-bold"
              >
                <p class="text-[14px] xs:text-xl">
                  {{ airport.name }}
                </p>
                <div
                  role="button"
                  tabindex="0"
                  class="text-[14px] xs:text-[15px] font-cocogoose mt-5 overflow-hidden overflow-ellipsis hover:bg-blue-700 bg-blue-600 rounded-md text-center cursor-pointer whitespace-nowrap max-w-min px-8 py-3"
                  @keypress.stop.prevent="searchParkings(airport)"
                  @click.stop.prevent="searchParkings(airport)"
                >
                  <span>
                    {{ $i18n("general.search_parking") }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LazyHydrate>
    </div>
  </div>
</template>

<script>
import LazyHydrate from "vue-lazy-hydration";
import {
  getQueryFromLocalStorage,
  setQueryInLocalStorage,
} from "~/helpers/date-sync";

export default {
  name: "Top3Air",
  props: {},
  components: {
    LazyHydrate,
  },
  data() {
    return {
      top3Airports: [],
    };
  },
  computed: {
    airports() {
      return [...this.$store.state.airports].map((val) => ({
        ...val,
        airportImage: val.airportImage.replace(
          "https://assets.parkos.com",
          "/_nuxt_home_page"
        ),
      }));
    },
    language() {
      return this.$store.state.language;
    },
    top3Air() {
      return this.$store.state.top3Air;
    },
  },
  created() {
    this.airports.forEach((airport) => {
      if (this.top3Air?.includes(airport.id)) {
        this.top3Airports.push(airport);
        this.top3Airports.sort(
          (a, b) =>
            this.top3Air.indexOf(a.id) -
            this.top3Air.indexOf(b.id)
        );
      }
    });
  },
  methods: {
    searchParkings(airportSelected) {
      dataLayer.push({
        event: "experiment_data",
        dl_experiment_id: "61/23",
        dl_experiment_action: "cta_click",
        dl_experiment_value: airportSelected.slug,
      });
      const now = new Date();
      const startDate = new Date(new Date(now).setDate(now.getDate() + 7));
      const endDate = new Date(new Date(now).setDate(now.getDate() + 14));
      const arrivalTime = "12:00";
      const departureTime = "12:00";
      const arrivalDate = this.formatApiDate(startDate);
      const departureDate = this.formatApiDate(endDate);
      const url = `/${airportSelected.slug}/${this.$i18n(
        "general.search-slug"
      )}/?location=${
        airportSelected.slug
      }&arrivalTime=${arrivalTime}&departureTime=${departureTime}&arrival=${arrivalDate}&departure=${departureDate}`;
      window.location.href = url;
      setQueryInLocalStorage({
        ...getQueryFromLocalStorage(this.$store.state.airport.slug),
        arrival: arrivalDate,
        arrivalTime,
        departure: departureDate,
        departureTime,
        location: airportSelected.slug,
      });
    },
    formatApiDate(date) {
      let day = date.getDate();
      if (day < 10) {
        day = `0${day}`;
      }
      let month = date.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      return `${date.getFullYear()}-${month}-${day}`;
    },
  },
};
</script>

<style scoped></style>
