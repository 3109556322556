<template>
  <div class="xs:container px-5 sm:px-10 pt-10 md:pt-[32px]">
    <div class="relative pt-0 md:pt-[48px]">
      <div
        class="text-white bg-blue-600 p-5 md:py-[65px] md:px-[60px] rounded-md"
      >
        <nuxt-img
          class="block md:hidden w-full object-cover"
          src="/_nuxt_home_page/assets/img/spring-banner.png"
          alt="SpringParking"
          loading="lazy"
        />
        <h2 class="font-cocogoose text-base md:text-3xl mt-7.5 md:mt-0">
          Doe mee en win een <br class="hidden md:block"> American Tourister koffer
        </h2>
        <p
          class="mt-2.5 max-w-[500px]  opacity-80 text-md md:text-[16px] leading-[1.88]"
        >
          Vertel ons naar welke vakantiebestemming je het meest uitkijkt en <br class="hidden md:block"> maak
          kans op een American Tourister koffer ter waarde van €143
        </p>
        <a
          role="button"
          tabindex="0"
          class="block mt-2.5 font-cocogoose text-[15px] overflow-hidden overflow-ellipsis hover:bg-blaze-orange-500 bg-orange-500 text-white rounded-md text-center cursor-pointer whitespace-nowrap max-w-min px-8 py-3"
          :href="SpringLandingURL"
        >
          <span> Doe mee </span>
        </a>
      </div>
      <nuxt-img
        format="webp"
        class="hidden md:block absolute top-10 right-10 max-h-[350px] w-auto object-cover"
        src="/_nuxt_home_page/assets/img/spring-banner.png"
        alt="SpringParking"
        loading="lazy"
        height="auto"
        width="auto"
      />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    SpringLandingURL() {
      return process.env.NODE_ENV === "production"
        ? "https://parkos.nl/winactie/"
        : "https://staging.parkos.nl/winactie/";
    },
  },
};
</script>

<style lang="scss" scoped></style>
