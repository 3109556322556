import Header from '~/components/header/Header.vue';
<template>
  <div class="flex mx-auto transform md:gap-x-5 gap-x-2 -translate-y-12 justify-center">
    <div
      v-for="review in reviews"
      :key="review.name"
      class="flex justify-between bg-white rounded-md md:p-5 p-2.5 shadow-card items-start"
    >
      <div class="flex flex-col text-[14px] text-left">
        <div class="flex items-center">
          <img
            :src="`/_nuxt_home_page/icons/${review.imgPath}.svg`"
            alt="google"
            width="20"
            height="20"
            loading="lazy"
            class="mr-2.5"
          />
          <h6 class="font-bold">{{ review.name }}</h6>
        </div>
        <div class="flex items-center">
          <div>
            <Stars class="md:flex hidden mr-2" :iconName="starsIcons" :rating="reviewsRate(review.name)" :spacing="false" />
            <svg
              svg
              viewBox="0 0 24 24"
              width="16"
              height="16"
              xmlns="http://www.w3.org/2000/svg"
              class="md:hidden block"
            >
              <path
                d="M12 2l2.92 6.26L22 9.27l-5 4.87 1.18 6.86L12 17.77l-6.18 3.24L7 14.14 2 9.27l7.08-1.01L12 2z"
                fill="#FFBD00"
              />
            </svg>
          </div>
          <h6 class="font-bold">
            {{ reviewsRate(review.name) }}
            <span class="text-manatee">/{{ review.name === "Parkos" ? 10 : 5 }} </span>
          </h6>
        </div>
        <span class="text-manatee truncate sm:w-auto w-20">
          {{
            $i18n("customer.num-reviews", {
              total: numberFormat(reviewsCount(review.name)),
            })
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Stars from "~/components/header/MetaReviews/Stars.vue";
import { trustpilot } from "~/constants/reviews";

export default {
  components: {
    Stars,
  },
  data() {
    return {
      reviews: [
        {
          name: "Google",
          imgPath: "url",
        },
        {
          name: "Trustpilot",
          imgPath: "trustpilot",
        },
        {
          name: "Parkos",
          imgPath: "parkos",
        },
      ],
      starsIcons: {
        star_full: "Star.svg",
        star_half: "StarHalf.svg",
      },
    };
  },
  computed: {
    reviewsMeta() {
      return this.$store.state.reviewsMeta;
    },
    language() {
      return this.$store.state.language;
    },
    reviewsScoreAvg() {
      if (!this.reviewsMeta) {
        return 0;
      }
      if (this.reviewsMeta.reviews.score) {
        return this.reviewsMeta.reviews.score;
      }
      return 0;
    },
    count() {
      if (!this.reviewsMeta) {
        return 0;
      }
      return this.reviewsMeta.reviews.count;
    },
    isFrenchDomain() {
      return this.language.lang === "fr" || this.language.lang === "fr-be";
    },
  },
  methods: {
    reviewsCount(reviewType) {
      if (reviewType === "Google") return 7925;
      if (reviewType === "Parkos") return this.count;
      if (reviewType === "Trustpilot")
        return trustpilot[this.language?.lang]?.reviews_count;
      return 0;
    },
    reviewsRate(reviewType) {
      if (reviewType === "Google") return 4.4;
      if (reviewType === "Parkos") return this.formatRating(this.reviewsScoreAvg);
      if (reviewType === "Trustpilot") return this.formatRating(trustpilot[this.language?.lang]?.rate);
      return 0;
    },
    formatRating(rating){
      const fixed = Number(rating)?.toFixed(1);
      const diff = fixed - ~~fixed;
      if(diff === 0){
        return Number(fixed).toFixed(0)
      }
      return fixed
    },
    numberFormat(number) {
      return new Intl.NumberFormat(
        this.isFrenchDomain ? "nl" : this.language.lang
      ).format(number);
    },
  },
};
</script>
