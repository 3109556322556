<template>
  <div class="xs:container px-5 sm:px-10 pt-10 md:pt-[32px]">
    <div class="relative pt-0 md:pt-[48px]">
      <div
        class="text-white bg-blue-600 p-5 md:py-[65px] md:px-[60px] rounded-md"
      >
        <nuxt-img
          class="block md:hidden w-full object-cover"
          src="/_nuxt_home_page/assets/img/christmas-banner.png"
          alt="ChristmasParking"
          loading="lazy"
        />
        <h2 class="font-cocogoose text-base md:text-3xl mt-7.5 md:mt-0">
          Vuoi vincere una valigia gratis?
        </h2>
        <p
          class="mt-2.5 max-w-[500px] leading-7 opacity-80 text-md md:text-base"
        >
          Raccontaci qual è la tua meta invernale preferita e vinci una valigia
          Samsonite del valore di 429€
        </p>
        <a
          role="button"
          tabindex="0"
          class="block mt-2.5 font-cocogoose text-[15px] overflow-hidden overflow-ellipsis hover:bg-blaze-orange-500 bg-orange-500 text-white rounded-md text-center cursor-pointer whitespace-nowrap max-w-min px-8 py-3"
          :href="ChristmasLandingURL"
        >
          <span> Partecipa </span>
        </a>
      </div>
      <nuxt-img
      format="webp"
        class="hidden md:block absolute top-6 right-6 max-h-[350px] w-auto object-cover"
        src="/_nuxt_home_page/assets/img/christmas-banner.png"
        alt="ChristmasParking"
        loading="lazy"
        height="auto"
        width="auto"
      />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    ChristmasLandingURL() {
      return process.env.NODE_ENV === "production"
        ? "https://parkos.it/promozione/"
        : "https://staging.parkos.it/promozione/";
    },
  },
};
</script>

<style lang="scss" scoped></style>
