<template>
  <div
    class="absolute top-0 right-0 w-72 bg-[#424242] text-white p-2.5 text-[14px]"
    style="z-index: 9999999999"
  >
    <div v-for="experiment in experiments" :key="experiment.flag" >
      <label :for="experiment.flag">{{experiment.flag}}:</label>

      <select :name="experiment.flag" :id="experiment.flag" :value="features[experiment.flag]|| null"  class="text-black" @change="setFeatures(experiment.flag, $event)">
        <option value="">Select</option>
        <option
          v-for="(variation, index) in experiment.variations"
          :key="index"
          @change="setFeatures(experiment.flag, variation)"
          :value="variation"
        >
          {{ variation }}
        </option>
      </select>
    </div>
    <button @click="applyFeatures" class="w-full mt-2 text-[14px] px-2  font-cocogoose cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap rounded-md bg-orange-500 py-1 text-center text-white hover:bg-blaze-orange-500">
        Apply Flags
    </button>
  </div>
</template>

<script>
export default {
  name: "ExperimentsToggleController",
  data() {
    return {
      experiments: [
        { flag: "homNlSpringCampaign2", variations: ["a", "b", "c"] },
        { flag: "CookieBanner", variations: ["a", "b", "c"] },
      ],
      features: { ...this.$route.query },
    };
  },
  methods: {
    setFeatures(flag, variation) {
        if(variation.target.value){
            this.features[flag] = variation.target.value;
            this.$router.push({ path: "", query: { ...this.features } });
        }
    },
    applyFeatures(){
      location.reload();
    }
  },
};
</script>
