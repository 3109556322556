<template>
  <div class="bg-white">
    <experiment-toggle name="homAddExternalReviews">
      <template #a />
      <template #b>
        <ExternalReviews />
      </template>
    </experiment-toggle>
    <experiment-toggle name="homSpringCampaign2">
      <template #a />
      <template #b>
        <SpringBanner />
      </template>
      <template #c>
        <SpringCornerButton />
      </template>
    </experiment-toggle>

    <experiment-toggle name="homNlSpringCampaign2">
      <template #a />
      <template #b>
        <NlSpringBanner />
      </template>
      <template #c>
        <NlSpringCornerButton />
      </template>
    </experiment-toggle>
    <div v-if="!be606ukQuickWinsRunning" class="py-10 md:py-20">
      <div class="xs:container px-5 sm:px-10">
        <LazyHydrate never>
          <Usps />
        </LazyHydrate>
      </div>
    </div>

    <top-3-air />
    <div
      class="relative object-cover"
      style="
        background-image: url(/_nuxt_home_page/backgrounds/Asset_background_home.svg);
      "
    >
      <div ref="howItWorks" class="xs:container px-5 sm:px-10 relative z-10">
        <div class="pt-10 pb-0 md:pt-20 md:pb-[30px] z-10">
          <template>
            <HowItWorks />
          </template>
        </div>
        <div
          role="button"
          tabindex="0"
          class="font-cocogoose text-[13px] sm:text-[15px] overflow-hidden overflow-ellipsis hover:bg-blaze-orange-500 bg-orange-500 text-white rounded-md text-center cursor-pointer whitespace-nowrap mx-auto self-center max-w-min px-8 py-3 block mt-[30px] sm:mt-0"
          @keypress.stop.prevent="bookNow"
          @click.stop.prevent="bookNow"
        >
          <span>
            {{ be606ukQuickWinsRunning ? "Book parking space" : $i18n("general.book") }}
          </span>
        </div>
      </div>
      <experiment-toggle name="homSpringCampaign" class="relative z-10">
        <template #a />
        <template #b>
          <spring-campaign-section />
        </template>
      </experiment-toggle>
      <experiment-toggle name="homChristmasCampaign" class="relative z-10">
        <template #a />
        <template #b>
          <christmas-campaign-section />
        </template>
      </experiment-toggle>

      <div
        v-if="shouldShowReviews"
        class="reviews-section bg-repeat-x bg-bottom relative z-10"
      >
        <LazyHydrate when-idle>
          <Reviews class="xs:container px-5 sm:px-10 reviews-section__carousel" />
        </LazyHydrate>
      </div>
      <div
        class="pb-10 md:pb-20 relative z-10 bg-[#f9fbfe]"
        :class="{ 'md:pt-20 pt-10 mt-10 md:mt-20': !shouldShowReviews }"
      >
        <div class="xs:container px-5 sm:px-10">
          <LazyHydrate when-visible>
            <Airports class="bg-[#f9fbfe]" />
          </LazyHydrate>
        </div>
      </div>
    </div>
    <experiment-toggle name="hom-stick-btn-to-book">
      <template #a />
      <template #b>
        <div>
          <StickyBookBtn />
        </div>
      </template>
    </experiment-toggle>
    <div class="bg-white py-5 md:py-10 my-10">
      <div v-if="homeContent.page_content" class="container px-5 sm:px-10 cms-content">
        <div class="cms-content__content" v-html="homeContent.page_content" />
      </div>
    </div>
    <LazyHydrate when-idle>
      <HelpButton
        @click="
          sendEvent('Parky_Click', 'Action Tracking', 'Hom-Remove-links-at-the-header')
        "
        :language="language.lang"
      />
    </LazyHydrate>
    <client-only>
      <noscript>
        <iframe
          :src="`https://www.googletagmanager.com/ns.html?id=${gtmKey}&noscript=`"
          height="0"
          width="0"
          style="display: none; visibility: hidden"
        ></iframe>
        <iframe
          v-if="cheqDomains[$paths.host]"
          :src="cheqDomains[$paths.host].nojs"
          width="0"
          height="0"
          style="display: none"
        ></iframe>
      </noscript>
    </client-only>
    <ExperimentToggleController v-if="$route.query.ec === 'qa'" />
  </div>
</template>

<script>
import LazyHydrate from "vue-lazy-hydration";
import Usps from "~/components/usps/Usps.vue";
import Reviews from "~/components/reviews/Reviews.vue";
import HelpButton from "~/components/airport/HelpButton.vue";
import cheqDomains from "~/constants/cheq";
import ExperimentToggle from "~/components/experiments/ExperimentToggle.vue";
import ExperimentToggleController from "~/components/experiments/ExperimentToggleController.vue";

import Airports from "~/components/airports/Airports.vue";
import ChristmasCampaignSection from "~/components/experiments/be-693-Christmas-campaign/ChristmasCampaignSection.vue";
import SpringCampaignSection from "~/components/experiments/be-865-spring-campaign/SpringCampaignSection.vue";
import SpringBanner from "~/components/experiments/be-964-spring-campaign-2.0/SpringBanner.vue";
import SpringCornerButton from "~/components/experiments/be-964-spring-campaign-2.0/SpringCornerButton.vue";
import NlSpringBanner from "~/components/experiments/be-1026-spring-campaign/NlSpringBanner.vue";
import NlSpringCornerButton from "~/components/experiments/be-1026-spring-campaign/NlSpringCornerButton.vue";
import ExternalReviews from "~/components/experiments/be-1141-add-external-reviews/ExternalReviews.vue";
import Top3Air from "~/components/Top3Air.vue";
import StickyBookBtn from "~/components/experiments/be-1071-Sticky-CTA-to-book-Mobile/StickyBookBtn.vue";
import { getCookie, nginxDecodeUserId } from "~/helpers/cookieHelpers";
import HowItWorks from "~/components/experiments/be-1325-how-it-works-section/index.vue";

// Language mapping for special domains with alternative languages
const specialDomainLanguages = {
  "de-ch.parkos.com": "dv", // Divehi
  "eu.parkos.com": "eo", // Esperanto
  "fr-ch.parkos.com": "fur", // Friulian
  "fr.parkos.be": "ff", // Fulah
  "it-ch.parkos.com": "smn", // Inari Sami
  "parkos.com.au": "ebu", // Embu
  "parkos.co.uk": "ee", // Ewe
};
export default {
  components: {
    ExperimentToggleController,
    ExperimentToggle,
    LazyHydrate,
    HelpButton,
    Usps,
    Reviews,
    Airports,
    ChristmasCampaignSection,
    Top3Air,
    SpringCampaignSection,
    SpringBanner,
    SpringCornerButton,
    NlSpringBanner,
    NlSpringCornerButton,
    StickyBookBtn,
    ExternalReviews,
    HowItWorks,
  },
  layout: "search",
  data() {
    return {
      stepperList: [
        {
          img: "step1edited.jpg",
          title: this.$i18n("templates.valet-parking-step-1-title"),
          description: this.$i18n("general.hdiw-step-1"),
          alt: this.$i18n("customer.referral-how-it-works"),
        },
        {
          img: "step2edited.jpg",
          title: this.$i18n("templates.valet-parking-step-2-title"),
          description: this.$i18n("general.hdiw-step-2"),
          alt: this.$i18n("customer.referral-how-it-works"),
        },
        {
          img: "step3edited.jpg",
          title: this.$i18n("templates.valet-parking-step-3-title"),
          description: this.$i18n("general.hdiw-step-3"),
          alt: this.$i18n("customer.referral-how-it-works"),
        },
        {
          img: "step4edited.jpg",
          title: this.$i18n("templates.valet-parking-step-4-title"),
          description: this.$i18n("general.hdiw-step-4"),
          alt: this.$i18n("customer.referral-how-it-works"),
        },
        {
          img: "step5edited.jpg",
          title: this.$i18n("templates.valet-parking-step-5-title"),
          description: this.$i18n("general.hdiw-step-5"),
          alt: this.$i18n("customer.referral-how-it-works"),
        },
      ],
      be606ukQuickWinsRunning: false,
      observer: null,
      options: {
        root: null,
        threshold: "0",
      },
    };
  },
  head() {
    const links = [
      {
        rel: "canonical",
        href: `https://${this.$paths.host}`,
      },
      {
        rel: "alternate",
        hreflang: this.getHrefLang,
        href: this.$paths.url(false),
      },
    ];
    const alternates = this.languages
      .filter((l) => l.id !== this.language.id)
      .map((m) => ({
        rel: "alternate",
        hreflang: m.lang === "en-eu" ? "en" : m.lang,
        href: (!m.domain.includes("https://") ? "https://" : "") + m.domain,
      }));
    links.push(...alternates);

    const script = [];

    if (process.env.DATA_ANALYTICS_BUCKET === "1") {
      const bucketSourcePrefix =
        process.env.NODE_ENV === "production" ? "/_nuxt_home_page" : "";
      const bucketSource = `${bucketSourcePrefix}/da-bucket/dist/home-page/index.js`;

      const bucketScript = {
        src: bucketSource,
        body: true,
      };

      script.push(bucketScript);
    }

    if (this.$paths.host === "parkos.nl") {
      const attrs = {
        src: `https://www.googletagmanager.com/gtag/js?id=${this.$config.gtagTrackingId}`,
        async: true,
      };
      script.push(attrs);
    }

    // CHEQ
    if (this.$paths.host in cheqDomains) {
      script.push({
        src: cheqDomains[this.$paths.host].js,
        "data-ch": this.$paths.host,
        class: cheqDomains[this.$paths.host].class,
        async: true,
        "data-jsonp": "onCheqResponse",
      });
    }

    // if (this.$store?.getters?.experimentVariant('BE1356CookieBanner') === 'b') {
    script.push({
      id: "Cookiebot",
      src: "https://consent.cookiebot.com/uc.js",
      "data-cbid": "ea7b04b3-d681-4591-99a4-50bdcf5fa1d5",
      "data-culture": this.getCookiebotLanguage(this.language.domain, this.language.lang),

      type: "text/javascript",
      async: true,
    });
    // }

    return {
      title: this.homeContent.metatitle,
      htmlAttrs: {
        lang: this.getHrefLang,
      },
      meta: [
        {
          "http-equiv": "content-language",
          content: this.getHrefLang,
        },
        {
          property: "og:title",
          content: this.homeContent.metatitle,
        },
        {
          name: "description",
          content: this.homeContent.metadescription,
        },
        {
          property: "og:description",
          content: this.homeContent.metadescription,
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:title",
          content: this.homeContent.metatitle,
        },
        {
          name: "twitter:site",
          content: "@ParkosInt",
        },
        {
          name: "twitter:creator",
          content: "@ParkosInt",
        },
        {
          name: "twitter:description",
          content: this.homeContent.metadescription,
        },
        {
          name: "twitter:image",
          content: `${this.$paths.assetsUrl}img/parkos_twitter_hero.png`,
        },
        {
          property: "og:type",
          content: "place",
        },
        {
          property: "og:locale",
          content: this.getHrefLang,
        },
        {
          property: "og:url",
          content: this.$paths.url(false) + this.$route.path,
        },
        {
          name: "og:image",
          content: `${this.$paths.assetsUrl}img/parkos_twitter_hero.png`,
        },
      ],
      link: links,
      script,
      __dangerouslyDisableSanitizersByTagID: {
        datalayer: ["innerHTML"],
      },
    };
  },
  computed: {
    active() {
      return this.$store?.getters?.isExperimentEnabled("hom-how-it-works");
    },
    variant() {
      return this.$store?.getters?.experimentVariant("hom-how-it-works");
    },
    isNlDe() {
      return (
        this.$store.state.language.lang === "nl" ||
        this.$store.state.language.lang === "de"
      );
    },
    shouldShowReviews() {
      return (
        this.reviewsMeta?.reviews.count >= 100 &&
        this.reviewsMeta?.reviews.score >= (this.isUSDomain ? 8 : 4)
      );
    },
    isUSDomain() {
      return this.$store.state.language.lang === "en-us";
    },
    cheqDomains() {
      return cheqDomains;
    },
    gtmKey() {
      return this.language.gtm_key;
    },
    reviewsMeta() {
      return this.$store.state.reviewsMeta;
    },
    reviews() {
      let reviewsForJsonLD = [];
      reviewsForJsonLD = this.$store.state.reviews.map((review) => {
        const ModifiedReview = {
          "@type": "Review",
          author: {
            "@type": "Person",
            name: review?.name,
          },
          datePublished: review?.date ?? "",
          reviewBody: review?.content ?? "",
          reviewRating: {
            "@type": "Rating",
            bestRating: this.isUSDomain ? 5 : 10,
            ratingValue: this.reviewScoreValue(review.score),
            worstRating: 1,
          },
        };
        reviewsForJsonLD.push(ModifiedReview);
        return ModifiedReview;
      });
      return reviewsForJsonLD;
    },
    homeContent() {
      return this.$store.state.pageContent.home || {};
    },
    airportData() {
      return this.$store.state.airportData;
    },
    language() {
      return this.$store.state.language;
    },
    languages() {
      return this.$store.state.languages;
    },
    maxRating() {
      if (this.language.lang === "en-us") {
        return 5;
      }
      return 10;
    },
    totalRating() {
      if (this.language.lang === "en-us") {
        return (this.reviewsMeta.reviews.score / 2)?.toFixed(1).replace(/[.,]0$/, "");
      }
      return this.reviewsMeta.reviews.score?.toFixed(1).replace(/[.,]0$/, "");
    },
    steps() {
      return this.stepperList.map((step) => ({
        ...step,
        img: `/_nuxt_home_page/assets/img/static/${step.img}`,
      }));
    },
    getHrefLang() {
      return this.language.lang === "en-eu" ? "en" : this.language.lang;
    },
  },
  created() {
    this.be606ukQuickWinsRunning =
      this.$store?.getters?.experimentVariant(this.name) === "b";

    if (process.client) {
      // eslint-disable-next-line nuxt/no-globals-in-created
      window.addEventListener("error", (error) => {
        try {
          // prevent doublefault
          this.$store.commit("bucket/update", {
            key: "error_line",
            value: error.lineno,
          });
          this.$store.commit("bucket/update", {
            key: "error_url",
            // eslint-disable-next-line no-restricted-globals
            value: location.href,
          });
          this.$store.commit("bucket/update", {
            key: "error_message",
            value: error.message,
          });
        } catch (ex) {}
      });
    }
  },
  jsonld() {
    return {
      "@context": "http://schema.org",
      "@type": "Organization",
      name: "Parkos",
      description: this.homeContent.metadescription, // <- Meta description of Home Page
      foundingDate: "2014",
      founders: [
        {
          "@type": "Person",
          name: "Peter Bosma, Arne Bos",
        },
      ],
      address: {
        "@type": "PostalAddress",
        streetAddress: "Hereweg 120-3",
        addressLocality: "Groningen",
        addressRegion: "GR",
        postalCode: "9725 AK",
        addressCountry: "NL",
      },
      alternateName: "Parkos B.V.",
      url: this.$paths.host, // current domain here
      logo: "https://assets.parkos.com/assets/img/logos/logo-vierkant.png",
      contactPoint: [
        {
          // contact info for all regions, should be kept as full list for all domains
          "@type": "ContactPoint",
          telephone: "050 808 0177",
          email: "support@parkos.nl",
          contactType: "customer service",
          areaServed: "NL",
          availableLanguage: "nl",
        },
        {
          "@type": "ContactPoint",
          telephone: "02 588 97 47",
          email: "support@parkos.nl",
          contactType: "customer service",
          areaServed: "BE",
          availableLanguage: "nl",
        },
        {
          "@type": "ContactPoint",
          telephone: "0 800 90 05 83",
          email: "support@parkos.fr",
          contactType: "customer service",
          areaServed: "BE",
          availableLanguage: "fr",
        },
        {
          "@type": "ContactPoint",
          telephone: "0061 2 7908 9702",
          email: "support@parkos.com",
          contactType: "customer service",
          areaServed: "AU",
          availableLanguage: "en",
        },
        {
          "@type": "ContactPoint",
          telephone: "0800 1810 516",
          email: "support@parkos.de",
          contactType: "customer service",
          areaServed: "AT",
          availableLanguage: "de",
        },
        {
          "@type": "ContactPoint",
          telephone: "0044 330 818 7958",
          email: "support@parkos.com",
          contactType: "customer service",
          areaServed: "DK",
          availableLanguage: "en",
        },
        {
          "@type": "ContactPoint",
          telephone: "0800 1810 516",
          email: "support@parkos.de",
          contactType: "customer service",
          areaServed: "DE",
          availableLanguage: "de",
        },
        {
          "@type": "ContactPoint",
          telephone: "0034 911 23 05 09",
          email: "support@parkos.es",
          contactType: "customer service",
          areaServed: "ES",
          availableLanguage: "es",
        },
        {
          "@type": "ContactPoint",
          telephone: "0044 330 818 7958",
          email: "support@parkos.com",
          contactType: "customer service",
          areaServed: "150",
          availableLanguage: "en",
        },
        {
          "@type": "ContactPoint",
          telephone: "0 800 90 05 83",
          email: "support@parkos.fr",
          contactType: "customer service",
          areaServed: "FR",
          availableLanguage: "fr",
        },
        {
          "@type": "ContactPoint",
          telephone: "0039 06 9480 9406",
          email: "support@parkos.it",
          contactType: "customer service",
          areaServed: "IT",
          availableLanguage: "it",
        },
        {
          "@type": "ContactPoint",
          telephone: "0031 50 808 0177",
          email: "support@parkos.com",
          contactType: "customer service",
          areaServed: "PL",
          availableLanguage: "en",
        },
        {
          "@type": "ContactPoint",
          telephone: "0034 911 23 05 09",
          email: "support@parkos.es",
          contactType: "customer service",
          areaServed: "PT",
          availableLanguage: "pt",
        },
        {
          "@type": "ContactPoint",
          telephone: "0044 330 818 7958",
          email: "support@parkos.se",
          contactType: "customer service",
          areaServed: "SE",
          availableLanguage: "en",
        },
        {
          "@type": "ContactPoint",
          telephone: "0044 330 818 7958",
          email: "support@parkos.co.uk",
          contactType: "customer service",
          areaServed: "GB",
          availableLanguage: "en",
        },
        {
          "@type": "ContactPoint",
          telephone: "001 929 209 5112",
          email: "support@parkos.com",
          contactType: "customer service",
          areaServed: "US",
          availableLanguage: "en",
        },
      ],
      sameAs: [
        // All websites associated with the Parkos brand, should be kept as full list for all domains
        "https://www.facebook.com/ParkosInternational",
        "https://twitter.com/ParkosInt",
        "https://www.instagram.com/parkos.international/",
        "https://www.youtube.com/channel/UCNu4H1PkMKxmEwrVnFKy4ZA",
        "https://www.trustpilot.com/review/parkos.nl",
        "https://www.trustpilot.com/review/fr.parkos.be",
        "https://www.trustpilot.com/review/parkos.be",
        "https://www.trustpilot.com/review/parkos.co.uk",
        "https://www.trustpilot.com/review/parkos.com",
        "https://www.trustpilot.com/review/parkos.es",
        "https://www.trustpilot.com/review/parkos.fr",
        "https://www.trustpilot.com/review/parkos.it",
        "https://www.trustpilot.com/review/parkos.se",
        "https://www.trustedshops.de/bewertung/info_XAC5772EB37A5389BE138F3AA6AD443EB.html",
        "https://parkos.nl/",
        "https://parkos.com.au/",
        "https://parkos.at/",
        "https://parkos.be/",
        "https://fr.parkos.be/",
        "https://parkos.dk/",
        "https://parkos.de/",
        "https://parkos.es/",
        "https://eu.parkos.com/",
        "https://parkos.fr/",
        "https://parkos.it/",
        "https://parkos.pl/",
        "https://parkos.pt/",
        "https://parkos.se/",
        "https://parkos.co.uk/",
        "https://parkos.com/",
      ],
      aggregateRating: {
        ...(this.shouldShowReviews
          ? {
              "@type": "AggregateRating",
              ratingValue: +this.totalRating,
              bestRating: this.maxRating,
              worstRating: 1,
              reviewCount: this.reviewsMeta.reviews.count,
            }
          : {}),
      },
      review: [...this.reviews],
    };
  },
  unmounted() {
    this.observer.disconnect();
  },
  async mounted() {
    window.addEventListener("CookiebotOnDialogDisplay", () => {
      window.dataLayer.push({
        event: "experiment_data",
        dl_experiment_id: "BE-1356",
        dl_experiment_action: "impression",
        dl_experiment_value: "banner",
      });
      const configure = document.querySelector(
        "#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection"
      );
      const selectAll = document.querySelector(
        "#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll"
      );

      setTimeout(() => {
        selectAll?.addEventListener("click", (e) => {
          setTimeout(() => {
            window.dataLayer.push({
              event: "experiment_data",
              dl_experiment_id: "BE-1356",
              dl_experiment_action: "selection",
              dl_experiment_value: "accept_all",
            });
            this.sendEvent("accept_all", "Action Tracking", "cookieBanner");
          });
        });

        configure?.addEventListener("click", (e) => {
          setTimeout(() => {
            window.dataLayer.push({
              event: "experiment_data",
              dl_experiment_id: "BE-1356",
              dl_experiment_action: "selection",
              dl_experiment_value: "accept_selection",
            });
            this.sendEvent("accept_selection", "Action Tracking", "cookieBanner");
          });
        });
      });
      window.addEventListener(
        "CookiebotOnDecline",
        () => {
          if (window.Cookiebot.changed) {
            window.dataLayer.push({
              event: "experiment_data",
              dl_experiment_id: "BE-1356",
              dl_experiment_action: "selection",
              dl_experiment_value: "decline_all",
            });
            this.sendEvent("decline_all", "Action Tracking", "cookieBanner");
          }
        },
        false
      );
    });

    if (!this.$paDeviceDetector.isMobile()) {
      this.$store.dispatch("experimentVariant", {
        name: "homeSmartAutoComplete",
      });
    }
    // console.log(window.location.hostname,'ssss')
    this.loadGTM();
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      // @ts-ignore
      cId: this.getCookie("cId"),
      // @ts-ignore
      domainName: this.$paths.host || "",
      pageType: "home",
      token: "504Z8p2vH6TtWX7BJC0rYaArFl9sYKdAHfisTESx",
    });
    if (this.$paths.host === "parkos.nl") {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }

      gtag("js", new Date());

      gtag("config", process.env.GTAG_TRACKING_ID);
    }
    this.setSessionCookies();

    if (this.$paths.host === "parkos.de") {
      (function () {
        // eslint-disable-next-line no-underscore-dangle
        const _tsid = process.env.TS_ID;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-underscore-dangle
        const _tsConfig = {
          yOffset: "0",
          variant: "reviews",
          customElementId: "ts-badge",
          trustcardDirection: "",
          customBadgeWidth: "",
          customBadgeHeight: "",
          disableResponsive: "false",
          disableTrustbadge: "false",
        };
        // eslint-disable-next-line no-underscore-dangle
        const _ts = document.createElement("script");
        _ts.type = "text/javascript";
        _ts.charset = "utf-8";
        _ts.async = true;
        _ts.src = `//widgets.trustedshops.com/js/${_tsid}.js`;
        // eslint-disable-next-line no-underscore-dangle
        const __ts = document.getElementsByTagName("script")[0];
        __ts.parentNode.insertBefore(_ts, __ts);
      })();
    }

    setTimeout(() => {
      if (window.innerWidth < 990) {
        const events = ["pointerdown", "scroll", "click"];
        // trigger async load
        const triggeringFunction = (event) => {
          if (
            (event.type === "pointerdown" || event.type === "click") &&
            this.$device.isIos
          ) {
            event.target?.click?.();
          }
          this.$store.commit("components/load", ["mobileDatePicker"]);
        };
        events.forEach((event) => {
          window.addEventListener(event, triggeringFunction, {
            capture: true,
            once: true,
          });
        });
      }
    });
  },
  methods: {
    handleIntersect(entry) {
      if (entry.isIntersecting) {
        this.$store.commit("experiments/addExperiment", {
          name: "hom-how-it-works",
          variant: this.variant,
        });
        this.observer.disconnect();
      }
    },
    async sendEvent(ea, ec, el) {
      const { host } = window.location;
      const pageType = () =>
        window.$nuxt?.$store?.state?.bucket?.page_type || window.pageType;

      const visitorIdCookie = getCookie("uid");
      if (!visitorIdCookie) {
        console.log("[FLAGSHIP GTM] VisitorId is not defined");
        return;
      }
      const visitorId = nginxDecodeUserId(visitorIdCookie);
      const flagshipEnvironmentId = this.$flagshipEnvironmentId;
      const flagshipEventsAPIEndpoint = this.$config.FLAGSHIP_EVENTS_API_BASE_URL;

      const response = await fetch(
        `${flagshipEventsAPIEndpoint}?host=${host}&app=${pageType()}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            cid: flagshipEnvironmentId,
            vid: visitorId,
            dl: document.location.href,
            ds: "APP",
            ev: 1,
            t: "EVENT",
            ea,
            ec,
            el,
          }),
        }
      );
      if (response.status !== 200) {
        console.error(
          `[FLAGSHIP GTM] Event could not be be sent, HTTP ${response.status}`
        );
      }
    },
    handleReferralCashbackCookie() {
      let cookie = this.getCookie("referral_cashback");
      if (cookie) {
        cookie = JSON.parse(decodeURIComponent(cookie));
      }
      this.$store.commit("referralCashback/set", cookie);
    },
    reviewScoreValue(score) {
      if (this.isUSDomain) {
        return parseFloat((score / 2).toFixed(1));
      }
      return +score;
    },
    bookNow() {
      this.$store.dispatch("airportPicker/toggle");
      this.sendEvent("reserveInteraction", "Action Tracking", "hom-how-it-works");

      setTimeout(() => {
        window.scrollTo({
          behavior: "smooth",
          top: 0,
        });
      }, 0);
    },
    loadGTM() {
      const { gtmKey } = this;
      if (gtmKey) {
        /* eslint-disable */
        window.loadTagManager = function (w, d, s, l) {
          w[l] = w[l] || [];
          w[l].push({
            "gtm.start": new Date().getTime(),
            event: "gtm.js",
          });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = `https://www.googletagmanager.com/gtm.js?id=${gtmKey}` + dl;
          f.parentNode.insertBefore(j, f);
        };
        if (typeof tagManagerDelay === "undefined") {
          window.loadTagManager(window, document, "script", "dataLayer");
        }
        /* eslint-enable */
      }
    },
    setSessionCookies() {
      // Tries to set session cookies based on query params and after that retries pushing the cIdto the datalayer if its set in the cId cookie
      const params = this.$route.query;

      this.$axios({
        method: "get",
        baseURL: "/",
        url: "ajax/set-session-cookie",
        params,
      })
        .then((result) => {
          window.dataLayer.push({
            cId: this.getCookie("cId"),
          });
        })
        .catch((error) => {
          // Add error handeling for failing session cookie links
          console.log(error);
        })
        .finally(() => {
          this.handleReferralCashbackCookie();
        });
    },
    getCookie(name) {
      const cookie = `; ${document.cookie}`;
      const cookieParts = cookie.split(`; ${name}=`);
      if (cookieParts.length === 2) {
        return cookieParts.pop().split(";").shift();
      }
      return "";
    },
    // Enhanced method to get the appropriate language for Cookiebot
    getCookiebotLanguage(currentDomain, currentLanguage) {
      // Check if the current domain needs a special language
      if (specialDomainLanguages[currentDomain]) {
        return specialDomainLanguages[currentDomain];
      }

      // Use the existing removeExtractLanguage method
      return this.removeExtractLanguage(currentLanguage);
    },

    // Existing method to remove language region
    removeExtractLanguage(languageCode) {
      const parts = languageCode.split("-");

      if (parts.length === 1) {
        return languageCode;
      }
      return parts[0];
    },
    numberFormat(number) {
      return new Intl.NumberFormat(this.language.lang).format(number);
    },
  },
};
</script>

<style lang="scss">
.cms-content {
  @apply text-[14px] xs:text-base;

  a {
    @apply text-blue-600;

    &:hover {
      @apply underline;
    }
  }

  h2 {
    &:first-child {
      @apply text-[16px] font-cocogoose xs:text-xl md:text-3xl mb-4;
    }
  }

  .row {
    @apply flex;

    & > .pull-right {
      @apply mt-5 flex-shrink-0 shrink-0 ml-5;
    }

    @media (max-width: 1000px) {
      @apply flex-col;

      & > .pull-right {
        @apply ml-0;
      }
    }
  }

  .btn.btn.btn-orange.btn-lg {
    @apply mt-2 hover:bg-blaze-orange-500 hover:no-underline font-bold flex-wrap flex flex-col xs:items-center items-start xs:flex-row max-w-max px-6 bg-orange-500 text-white py-3 rounded-md text-center cursor-pointer whitespace-nowrap;
  }
}

#trustbadge-container-98e3dadd90eb493088abdc5597a70810 {
  z-index: 39 !important;

  ._12n8yed {
    margin-bottom: 30px !important;
  }
}

.bg-clip-path-polygon {
  clip-path: polygon(0% 15%, 100% calc(100vh - 20vh), 100% 100%, 0% 100%);

  @media screen and (max-width: 990px) {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}
</style>
