<template>
  <div class="usps-wrapper">
    <div class="usps__title">
      {{ $i18n("templates.parkos-usps") }}
    </div>
    <div class="usps__container">
      <div class="usps__section">
        <div class="usps__section-card">
          <IconCar class="usps__section-card__icon" />
          <div>
            <div class="usps__section-card__title">
              {{ $i18n("templates.parkos-usp-title-1") }}
            </div>
            <div
              class="usps__section-card__description"
              v-html="$i18n('templates.parkos-usp-1')"
            />
          </div>
        </div>
      </div>
      <div class="usps__section">
        <div class="usps__section-card">
          <IconThumbsUp class="usps__section-card__icon" />
          <div>
            <div class="usps__section-card__title">
              {{ $i18n("templates.parkos-usp-title-2") }}
            </div>
            <div
              class="usps__section-card__description"
              v-html="$i18n('templates.parkos-usp-2')"
            />
          </div>
        </div>
      </div>
      <div class="usps__section">
        <div class="usps__section-card">
          <Icon24 class="usps__section-card__icon" />
          <div>
            <div class="usps__section-card__title">
              {{ $i18n("templates.parkos-usp-title-4") }}
            </div>
            <div
              class="usps__section-card__description"
              v-html="$i18n('templates.parkos-usp-4')"
            />
          </div>
        </div>
      </div>
      <div class="usps__section">
        <div class="usps__section-card">
          <IconCheck class="usps__section-card__icon" />
          <div>
            <div class="usps__section-card__title">
              {{ $i18n("templates.parkos-usp-title-3") }}
            </div>
            <div
              class="usps__section-card__description"
              v-html="$i18n('templates.parkos-usp-3')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconCar from "~/components/icons/usp/IconCar.vue";
import IconThumbsUp from "~/components/icons/usp/IconThumbsUp.vue";
import Icon24 from "~/components/icons/usp/Icon24.vue";
import IconCheck from "~/components/icons/usp/IconCheck.vue";

export default {
  components: {
    IconCar,
    IconThumbsUp,
    Icon24,
    IconCheck,
  },
};
</script>

<style lang="scss">
.usps {
  &-wrapper {
    @apply font-sans;
  }

  &__title {
    @apply text-[16px] font-cocogoose xs:text-xl md:text-[28px] mb-[40px];
  }

  &__container {
    @apply grid auto-cols-auto sm:grid-cols-2 gap-y-2 gap-x-4 lg:grid-cols-4 md:gap-x-10;
  }

  &__section {
    &-card {
      @apply flex items-start gap-x-[10px];

      &__icon {
        @apply fill-current text-blue-600 w-14 h-14 -mt-1 -ml-2;
      }
      &__title {
        @apply text-[14px] xs:text-base font-cocogoose mb-2.5;
      }
      &__description {
        @apply text-[14px] xs:text-base;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          @apply mb-0 font-bold;
        }
      }
    }
  }
}
</style>
