<template>
    <div
    v-if="showStickyBtn"
    class="w-full py-5 px-6 bg-white shadow-dropdown  flex flex-col items-center fixed  z-50 bottom-0 sm:hidden"
  >
    <button
      class="mb-[11px] w-full text-[14px] xs:text-[15px] font-cocogoose cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap rounded-md bg-orange-500 px-2 py-3 text-center text-white hover:bg-blaze-orange-500"
      @click.stop.prevent="scrollIntoSearch"
    >
      {{ $i18n("home.search-parkingplace") }}
    </button>
    <span class="text-md"> {{ $i18n("reservation.free-canceling-time-no-html", {time: 24}) }}</span>
  </div>
</template>

<script>

export default {
  data() {
    return {
      showStickyBtn: false,
    };
  },
  mounted() {
    const sectionElement = document.getElementById('search-section');

    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      console.log('entry: ', entry);
      if (!entry.isIntersecting) {
        this.showStickyBtn = true;
      } else {
        this.showStickyBtn = false;
      }
    });

    observer.observe(sectionElement);
  },
  methods: {
    scrollIntoSearch() {
      const targetSection = document.getElementById('__layout');
      targetSection.scrollIntoView({ behavior: 'smooth', black: 'start' });
    },
  },
};
</script>

<style lang="scss">
a[href^="#"] {
  scroll-behavior: smooth;
}
</style>
