// eslint-disable-next-line import/prefer-default-export
export const trustpilot = {
  nl: {
    rate: 4,
    reviews_count: 7590,
  },
  de: {
    rate: 4.5,
    reviews_count: 31.996,
  },
  it: {
    rate: 5,
    reviews_count: 22200,
  },
  fr: {
    rate: 4.6,
    reviews_count: 18.161,
  },
  es: {
    rate: 4.5,
    reviews_count: 20.997,
  },
};
